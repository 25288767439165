import React from "react";
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";

export default class Step9 extends React.Component {
    constructor(props) {
        super(props);
        this.handleSelected = this.handleSelected.bind(this);
    }

    handleSelected(e) {              
        this.props.manualHandleChange("activeMilitary", e.currentTarget.dataset.id);
        this.props.next();
    }

    componentDidMount() {
        ReactGA4.event({
            category: 'Step 9, Military Status',
            action: 'Step 9, Military Status',
            label: 'Step 9, Military Status'
        });       
        
    }

    render () {
        return (
            <div className="step-item col-md-10">
                <div id="section-military" className="section-question section-hide">
                    <div className="row question">
                        <div className="col-md-1"></div>    
                        <div className="col-md-10 text-center">
                            <p>Are you or your spouse a veteran or active military?</p>
                            <small>If so, you may qualify for a VA Interest Rate Reduction Refinance Loan (IRRRL).</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <div className="buttons military">
                                <div className={`yes ${this.props.values.activeMilitary === 'Yes'?'active':''}`} data-id="Yes" onClick={this.handleSelected}>
                                    <span className="icon"></span>
                                    <span className="text">Yes</span>
                                </div>

                                <div className={`no ${this.props.values.activeMilitary === 'No'?'active':''}`} data-id="No" onClick={this.handleSelected}>
                                    <span className="icon"></span>
                                    <span className="text">No</span>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center mt-5 mb-5">                            
                        <button type="button" id="btnsubmit-property-value" className="btn-block btn btn-lg btn-text-white btn-350 backBtn"
                                onClick={this.props.back}> Back </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
