import React from "react"
import Footer from "./Components/Footer";
import Header from "./Components/Header";

import Step1 from "./Formsteps/Step1";
import Step2 from "./Formsteps/Step2";
import Step3 from "./Formsteps/Step3";
import Step4 from "./Formsteps/Step4";
import Step5 from "./Formsteps/Step5";
import Final from "./Formsteps/Final";
import Step6 from "./Formsteps/Step6";
import Step7 from "./Formsteps/Step7";
import Step8 from "./Formsteps/Step8";
import Step9 from "./Formsteps/Step9";
import Step10 from "./Formsteps/Step10";
import Step11 from "./Formsteps/Step11";
import Step12 from "./Formsteps/Step12";
import Step13 from "./Formsteps/Step13";
import Step14 from "./Formsteps/Step14";

import axios from 'axios';
import queryString from 'query-string';
import ReactGA4 from "react-ga4";

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            signupSuccess: false,
            fullAddress : "",
            zillowHomeValue : "",
            address : "",
            city : "",
            state : "",
            zip : "",
            apt : "",
            homeType : "",
            creditScore : "",
            employment: "",
            estimatedHomeValue : "270000",
            remainingMortgageBalance : "210000",
            cashOut : "5000",
            ltvMax : "46500",
            activeMilitary : "",
            fhaLoan : "",
            email : "",
            firstName : "",
            lastName : "",
            phone : "",
            ipaddress : "",
            publisher_id : "",
            upload_type : "RDv1",
            subid : "",
            cake_id : "",
            rcid : "",
            hid : "",
            type : "REFINANCE",
            employmentStatus : "",
            dobMonth: "",
            dobDay: "",
            dobYear: "",
            dob: "",
            incorrectFields:[],
            errorMessage: {}
        };
        this.manualHandleChange = this.manualHandleChange.bind(this);
        this.next = this.next.bind(this);
        this.isError = this.isError.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.back = this.back.bind(this);
        this.setError = this.setError.bind(this);
    }
    
    componentDidMount() {
        ReactGA4.initialize(process.env.REACT_APP_GA);

        const getParameters = queryString.parse(window.location.search);
        let publisher_id,rcid,subid,cake_id = "";
        
        if (getParameters.publisher_id) {
            publisher_id = getParameters.publisher_id
        }else {
            publisher_id = 632;
        }

        if (getParameters.rcid) {
            rcid = getParameters.rcid
        }else {
            rcid = "";
        }

        if (getParameters.subid) {
            subid = getParameters.subid
        }else {
            subid = "";
        }

        if (getParameters.cake_id) {
            cake_id = getParameters.cake_id
        }else {
            cake_id = "";
        }

        this.manualHandleChange('publisher_id',publisher_id);
        localStorage.setItem('publisher_id',publisher_id);
        this.manualHandleChange('rcid',rcid);
        this.manualHandleChange('subid',subid);
        this.manualHandleChange('cake_id',cake_id);

        axios.get(process.env.REACT_APP_API_URL+'api/user/ip_address/').then(res => {
                this.manualHandleChange('ipaddress',res.data.ip_address);
            });

        axios.get(process.env.REACT_APP_API_URL+'api/hdi/',{
            params: {
                upload_type: this.state.upload_type,
                publisher_id: publisher_id
            }
        }).then(res => {
                this.manualHandleChange('hid',res.data.hid);
            });
    }

    manualHandleChange (name,val) {        
        this.setState({
            [name]: val
        });
        this.removeError(name);
    }

    next() {
        this.setState(prevState => {
            return {step: prevState.step + 1
        }});
        window.scrollTo(0, 0);
    }

    back() {
        this.setState(prevState => {
            return {step: prevState.step - 1
            }})
    }

    openMainPage() {
        this.setState({step:1});
        window.scrollTo(0, 0);
    }

    handleChange = input => e => {
        this.setState({
            [input]: e.target.value
        })
        this.removeError(input);
    }    

    handleSubmit() {
        this.setState({
            signupSuccess: true
        }, () => this.next())
    }

    setError(name, message="") {
        if (!this.isError(name)) {
            let errorList = this.state.incorrectFields;
            errorList.push(name);
            let errorMessage = this.state.errorMessage;
            errorMessage[name] = message;
            this.setState({
                incorrectFields: errorList,
                errorMessage: errorMessage
            });
        }
    }

    removeError(name) {
        if (this.isError(name)) {
            let errorList = this.state.incorrectFields;
            errorList = errorList.filter(item => item !== name);
            this.setState({
                incorrectFields: errorList
            });
        }
    }

    isError(name) {
        return (this.state.incorrectFields.indexOf(name) >= 0);
    }

    renderStep(step) {
        const values = this.state;

        switch (step) {
            case 1:
                // Zip Code
                return (
                    <Step1
                        values = {values}                        
                        handleChange = {this.handleChange}
                        next = {this.next}                        
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}                        
                    />
                )
            case 2:
                // Property Type
                return (
                    <Step2
                        values = {values}                        
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.back}             
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}                        
                    />
                )
            case 3:
                // Property Value
                return (
                    <Step3
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.back}
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}                        
                    />
                )
            case 4:
                // First Mortgage
                return (
                    <Step4
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.back}
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}                        
                    />
                )
            case 5:
                // Credit Score
                return (
                    <Step5
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.back}
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}                        
                    />
                )
            case 6:
                // CashOut
                return (
                    <Step6
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.back}
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}                        
                    />
                )
            case 7:
                // Employment
                return (
                    <Step7
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.back}
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}                        
                    />
                )
            case 8:
                // FHA Loan
                return (
                    <Step8
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.back}
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}                        
                    />
                )
            case 9:
                // Active Military
                return (
                    <Step9
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.back}
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}                        
                    />
                )
            case 10:
                // DOB
                return (
                    <Step10
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.back}
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}                        
                    />
                )
            case 11:
                // Address
                return (
                    <Step11
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.back}
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}                        
                    />
                )
            case 12:
                // Name
                return (
                    <Step12
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.back}
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}                        
                    />
                )
            case 13:
                // Email
                return (
                    <Step13
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.back}
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}                        
                    />
                )
            case 14:
                // Phone
                return (
                    <Step14
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.back}
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}                        
                    />
                )
            case 15:
                return (
                    <Final
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        handleSubmit = {this.handleSubmit}                        
                    />
                )
        }
    }

    render() {
        const { step } = this.state;        

        return (
            <React.Fragment>
                <Header step={this.state.step} />
                    <div id="survey" className="formContainer">
                        <div id="finance-survey">
                            <div className="container containerInFormContainer">
                                <div className="row">
                                {this.renderStep(step)}
                                </div>
                            </div>
                        </div>
                    </div>
                <div id="footer-page">
                <Footer step={this.state.step} />
                </div>
            </React.Fragment>
        );
    }
}
