import React from "react";
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";
import ReactBootstrapSlider from 'react-bootstrap-slider';
import CurrencyFormat from 'react-currency-format';

export default class Step3 extends React.Component {
    constructor(props) {
        super(props);          
        this.state = {
            currentValue: this.props.values.estimatedHomeValue,
            loanValue: this.props.values.remainingMortgageBalance
        };     
    }

    changeValue = e => {
        this.props.manualHandleChange('ltvMax',(e.target.value*0.95 - this.state.loanValue));
        this.props.manualHandleChange('cashOut',(e.target.value*0.8 - this.state.loanValue - (e.target.value*0.8 - this.state.loanValue)%5000));
        this.setState({ currentValue: e.target.value });
        this.props.manualHandleChange('estimatedHomeValue',e.target.value)
    };

    componentDidMount() {
        ReactGA4.event({
            category: 'Step 3, Home Value',
            action: 'Step 3, Home Value',
            label: 'Step 3, Home Value',
        });       
        
    }

    render () {
        return (
            <div className="step-item col-md-10">
                <div id="section-property-value" className="section-question section-hide">
                    <div className="row question">
                        <div className="col-md-1"></div>    
                        <div className="col-md-10 text-center">
                            <p>What's your home value?</p>
                            <small>It's ok to estimate.</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            {this.props.isError('estimatedHomeValue') &&
                                <div className="step-errors">
                                    {this.props.values.errorMessage['estimatedHomeValue']}
                                </div>
                            }
                            
                        </div>
                    </div>

                    <div className="row">
                    <div className="col-md-2"/>
                        <div className="col-md-8 text-center">
                            <div id="home-purchase-price-value" className="price-value">

                            {this.state.currentValue<=90000?'$90,000 or less':''}
                            {this.state.currentValue<2000000 && this.state.currentValue>90000?<span><CurrencyFormat value={this.state.currentValue-14999} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>      :''}
                            {this.state.currentValue > 90000 && this.state.currentValue<2000000?' to ':''}

                            {this.state.currentValue<2000000 && this.state.currentValue>90000?<span><CurrencyFormat value={this.state.currentValue} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>      :''}
                            {this.state.currentValue>=2000000?'Over $2,000,000':''}

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2"/>
                        <div className="col-md-8">
                            <div className="property-price-value">
                                <ReactBootstrapSlider
                                    step={15000}
                                    max={2010000}
                                    min={90000}
                                    orientation="horizontal"
                                    className="slider"
                                    tooltip="hide"
                                    value={this.state.currentValue}
                                    change={this.changeValue}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-2"/>
                        <div className="col-md-8">
                            <span className="range-min">$90,000</span><span className="range-max">$2,000,000+</span>
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">$</div>
                                </div>
                                <input type="number" className={`form-control ${!this.props.isError('estimatedHomeValue') ? '' : 'invalid'}`} id="property_price" 
                                    name="property_price" placeholder="" value={this.props.values.estimatedHomeValue} 
                                    onChange={this.props.handleChange('estimatedHomeValue')}  />
                            </div>
                        </div>
                    </div> */}

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center mt-5 mb-5">
                            <button type="button" className="btn-block btn  btn-lg btn-text-white btn-350 continueBtn"
                                onClick={this.props.next}> Continue </button>
                            <button type="button" id="btnsubmit-property-value" className="btn-block btn btn-lg btn-text-white btn-350 backBtn"
                                onClick={this.props.back}> Back </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
