import React from "react";
import ReactGA4 from "react-ga4";
import ReactBootstrapSlider from 'react-bootstrap-slider';
import CurrencyFormat from 'react-currency-format';
import {FaInfoCircle} from 'react-icons/fa';
import ReactTooltip from 'react-tooltip'

export default class Step6 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maxValue: this.props.values.ltvMax,
            currentValue: this.props.values.cashOut
        };
    }

    sliderHandler = e => {
        if(this.state.maxValue <= 0) {
            this.props.setError('cashOut', 'There is no equity/cash in your home to borrow');     
        }
    }

    currencyFormat(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    changeValue = e => {
        if(e.target.value == this.state.maxValue - this.state.maxValue%5000) {
            this.props.manualHandleChange('cashOut',this.state.maxValue);
        }else {
            this.props.manualHandleChange('cashOut',e.target.value)
        }
        this.setState({ currentValue: e.target.value });
    };

    componentDidMount() {
        ReactGA4.event({
            category: 'Step 6, Cash Out',
            action: 'Step 6, Cash Out',
            label: 'Step 6, Cash Out'
        });       
        
    }

    render () {
        return (
            <div className="step-item col-md-10">
                <div id="section-cashout" className="section-question section-hide">
                    <div className="row question">
                        <div className="col-md-1"></div>    
                        <div className="col-md-10 text-center">
                            Would you like to borrow additional cash? <FaInfoCircle data-tip="Use your home equity to pull cash out to pay off credit card bills, home improvement project, etc" style={{color:"#007bff",fontSize:"20px"}}/><ReactTooltip className="infoToolTip" type="light" multiline="true" border="true" eventOff="click" place="right"/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6 text-center">
                            {this.props.isError('cashOut') && this.state.maxValue <= 0 &&
                                <div className="step-errors">
                                    {this.props.values.errorMessage['cashOut']}
                                </div>
                            }
                            
                        </div>
                    </div>
                    <div
                        onClick={(e)=>{
                            if (this.state.maxValue <= 0) {
                                e.stopPropagation();
                                this.sliderHandler()
                            }
                        }}
                         onTouchStart={(e)=>{
                             if (this.state.maxValue <= 0) {
                                 e.stopPropagation();
                                 this.sliderHandler()
                             }
                         }}>
                    <div className="row">
                        <div className="col-md-2"/>
                        <div className="col-md-8 text-center">
                            <div id="home-purchase-price-value" className="price-value">
                                <span>{this.state.currentValue <= 0 ?'$0 (No Cash)': ''}</span>
                                {this.state.maxValue > 0? <span><CurrencyFormat value={this.state.currentValue-4999<0?'':this.state.currentValue>=this.state.maxValue - this.state.maxValue%5000?this.state.maxValue - this.state.maxValue%5000 - 4999:
                                this.state.currentValue-4999} displayType={'text'} thousandSeparator={true} prefix={'$'} />{this.state.currentValue<2000000?'':'+'}</span> : ''}

                                <span>{this.state.currentValue <= 0 ? '' : ' to '}</span>

                                {this.state.maxValue > 0? <span><CurrencyFormat 
                                value={this.state.currentValue-4999<0?' no cash out':this.state.currentValue==this.state.maxValue-this.state.maxValue%5000?this.state.maxValue:this.state.currentValue} displayType={'text'} thousandSeparator={true} prefix={'$'} />{this.state.currentValue<2000000?'':'+'}</span> :''}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2"/>
                        <div className="col-md-8">
                            {this.state.maxValue > 0 ?
                            <div className="property-price-value">
                                <ReactBootstrapSlider
                                    step={5000}
                                    max={this.state.maxValue}
                                    min={0}
                                    orientation="horizontal"
                                    className="slider"
                                    tooltip="hide"
                                    value={this.state.currentValue == this.state.maxValue - this.state.maxValue%5000?this.state.maxValue:this.state.currentValue}
                                    change={this.changeValue} 
                                />
                            </div>
                            :
                            <div className="property-price-value">
                            <ReactBootstrapSlider
                                step={5000}
                                max={this.state.maxValue}
                                min={0}
                                orientation="horizontal"
                                className="slider"
                                tooltip="hide"
                                value={this.state.currentValue}
                                handleChange={()=>this.sliderHandler()}
                                disabled="disabled"
                            />
                            </div>}
                        

                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-2"/>
                        <div className="col-md-8">
                            <span className="range-min">$0</span><span className="range-max">${this.state.maxValue <= 0 ? '400k':this.currencyFormat(this.state.maxValue)}</span>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center mt-5 mb-5">
                        <button type="button" className="btn-block btn  btn-lg btn-text-white btn-350 continueBtn"
                                onClick={this.props.next}> Continue </button>                          
                            <button type="button" id="btnsubmit-property-value" className="btn-block btn btn-lg btn-text-white btn-350 backBtn"
                                onClick={this.props.back}> Back </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
