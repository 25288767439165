import React from "react";
import ReactGA4 from "react-ga4";

export default class Step1 extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    fetchData = async () => {
        return await fetch('//www.reallygreatrate.com/api/zipcode_lookup/?zip=' + this.props.values.zip)
              .then((response) => response.json())
              .then((response) => {
                if(response.city == '') {
                    return false;
                }else {
                    this.props.manualHandleChange('city', response.city);
                    this.props.manualHandleChange('state', response.state);
                    return true;
                }
              });
      }

    async isValidZipCode(zip) {

        if(!this.props.values.zip.length) return false;

        if(/^\d{5}(-\d{4})?$/.test(zip)) {
            const res =  await this.fetchData();
            return res;
        }
        return false;
        
    }

    handleChange(e) {       
        if (e.currentTarget.value.match(/^[0-9\b]+$/) || e.currentTarget.value == "") {
            this.props.manualHandleChange(e.currentTarget.dataset.type, e.currentTarget.value)
        }      
    }

    submitStep = async () => {
        let isError = false;

        if (!this.props.values.zip.length) {
            this.props.setError('zip', "Please enter ZIP code");            
            isError = true;
        }
        const result = await this.isValidZipCode(this.props.values.zip);
        if(!result) {
            this.props.setError('zip', "Please enter valid ZIP code");            
            isError = true;
        }

        if (!isError) {
            this.props.next();
        }
    }

    componentDidMount() {
        ReactGA4.initialize(process.env.REACT_APP_GA);
        ReactGA4.event({
            category: 'RDv1 Landing',
            action: 'RDv1 Landing',
            label: 'RDv1 Landing'
        });             
        
    }

    render () {
        return (
            <div className="step-item col-md-10">
                <div id="section-property-type" className="section-question section-show">
                    <div className="row question">
                        <div className="col-md-1"></div>    
                        <div className="col-md-10 text-center">
                            <p>TAKE 60 SECOND QUIZ TO CALCULATE SAVINGS!</p>
                            <small>POTENTIALLY LOWER YOUR OVERALL MONTHLY PAYMENTS*<br></br>
                            CHECK IF YOU QUALIFY FOR REFINANCE OFFERS.</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            {this.props.isError('zip') &&
                                <div className="step-errors">
                                    {this.props.values.errorMessage['zip']}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 input-holder">
                            <input type="text" className={`form-control ${this.props.isError("zip")? 'invalid': ''}`}
                            data-type="zip" maxLength="5" placeholder="Please enter ZIP code" autoComplete="off" value={this.props.values.zip}
                            data-parsley-group="zip" data-parsley-required-message="Please enter ZIP code" required 
                            onChange={this.handleChange}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center mt-5 mb-5">
                            <button type="button" id="btnsubmit-property-value" className="btn-block btn  btn-lg btn-text-white btn-350 continueBtn"
                                onClick={this.submitStep}>See Savings</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
