import React from "react";
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";

export default class Step5 extends React.Component {
    constructor(props) {
        super(props);
        this.handleSelected = this.handleSelected.bind(this);
    }

    handleSelected(e) {        
        this.props.manualHandleChange("creditScore", e.target.dataset.id);
        this.props.manualHandleChange("creditScoreSelected", e.target.dataset.score);
        this.props.next();
    }

    componentDidMount() {
        ReactGA4.event({
            category: 'Step 5, Credit Score',
            action: 'Step 5, Credit Score',
            label: 'Step 5, Credit Score'
        });       
        
    }

    render () {
        return (
            <div className="step-item col-md-10">
                <div id="section-credit-score" className="section-question section-hide">
                    <div className="row question">
                        <div className="col-md-1"></div>    
                        <div className="col-md-10 text-center">
                            <p>How would you rate your credit?</p>
                            <small>Most people have a 660-719 or 720 and above</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <ul className="credit-score">
                                <li className={`excellent ${this.props.values.creditScoreSelected === '720'?'active':''}`} data-id="Excellent" data-score="720" onClick={this.handleSelected}>720 and above</li>
                                <li className={`good ${this.props.values.creditScoreSelected === '660'?'active':''}`} data-id="Good" data-score="660" onClick={this.handleSelected}>660-719</li>
                                <li className={`average ${this.props.values.creditScoreSelected === '620'?'active':''}`} data-id="Fair" data-score="620" onClick={this.handleSelected}>620-659</li>
                                <li className={`poor ${this.props.values.creditScoreSelected === '580'?'active':''}`} data-id="Poor" data-score="580" onClick={this.handleSelected}>580-619</li>
                                <li className={`poor ${this.props.values.creditScoreSelected === '579'?'active':''}`} data-id="Poor" data-score="579" onClick={this.handleSelected}>579 or below</li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center mt-5 mb-5">                          
                        <button type="button" id="btnsubmit-property-value" className="btn-block btn btn-lg btn-text-white btn-350 backBtn"
                                onClick={this.props.back}> Back </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
