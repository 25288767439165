import React from "react";
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";
import {FaInfoCircle} from 'react-icons/fa';
import ReactTooltip from 'react-tooltip'

export default class Step8 extends React.Component {
    constructor(props) {
        super(props);
        this.handleSelected = this.handleSelected.bind(this);
    }

    handleSelected(e) {              
        this.props.manualHandleChange("fhaLoan", e.currentTarget.dataset.id);
        this.props.next();
    }

    componentDidMount() {
        ReactGA4.event({
            category: 'Step 8, FHA Loan',
            action: 'Step 8, FHA Loan',
            label: 'Step 8, FHA Loan'
        });       
        
    }

    render () {
        return (
            <div className="step-item col-md-10">
                <div id="section-fha" className="section-question section-hide">
                    <div className="row question">
                        <div className="col-md-1"></div>    
                        <div className="col-md-10 text-center">
                            <p>Are you currently in an FHA loan? <FaInfoCircle data-tip="An FHA loan is a mortgage issued by an FHA-approved lender and insured by the Federal Housing Administration (FHA). Designed for low-to-moderate income borrowers, FHA loans require a lower minimum down payments and credit scores than many conventional loans." style={{color:"#007bff",fontSize:"20px"}}/><ReactTooltip className="infoToolTip" type="light" multiline="true" border="true" eventOff="click" place="right"/></p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <div className="buttons fha">
                                <div className={`yes ${this.props.values.fhaLoan === 'Yes'?'active':''}`} data-id="Yes" onClick={this.handleSelected}>
                                    <span className="icon"></span>
                                    <span className="text">Yes</span>
                                </div>

                                <div className={`no ${this.props.values.fhaLoan === 'No'?'active':''}`} data-id="No" onClick={this.handleSelected}>
                                    <span className="icon"></span>
                                    <span className="text">No</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center mt-5 mb-5">                            
                        <button type="button" id="btnsubmit-property-value" className="btn-block btn btn-lg btn-text-white btn-350 backBtn"
                                onClick={this.props.back}> Back </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
