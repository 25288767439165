import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/bootstrap.min.css';
import './assets/css/percircle.css';
import './assets/css/bootstrap-slider.css';
import './assets/css/style.css';
import './assets/css/clickwall.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import $ from './assets/js/jquery-1.9.1';
// import bootstrap from './assets/js/bootstrap';
// import bootstrapSlider from './assets/js/bootstrap-slider';
// import jqueryMask from './assets/js/jquery.mask';
// import parsley from './assets/js/parsley';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
