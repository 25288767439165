import React, { useState } from "react";
import Modal from "react-modal";

export default function AdDisclosurePopup(props) {
    const [isOpen, setIsOpen] = useState(false);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <a className={"pointer-cursor text-black-50"} onClick={toggleModal}>Advertising Disclosures</a>
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="Advertising disclosures"
                className="contentModal"
                overlayClassName="contentModalOverlay"
                ariaHideApp={false}
            >
                <div className="contentModalHeader">
                    <div className="row">
                        <div className="col-auto">
                            <h3>Advertising Disclosures</h3>
                            Mortgage Rate as low as 2.13% (2.54% APR) – Disclosure current as of 10-Aug-21.
                        </div>
                        <div className="col text-right">
                            <button type="button" className="close" onClick={toggleModal} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="contentModalText adDisclosure">
                    <ul>
                        <li>The advertised loan is a 5/1 ARM (Adjustable Rate Mortgage) with a 30-year fully amortizing term from actual offers posted to consumers by Network Lenders.</li>
                        <li>After the initial period, the variable interest rate and payment will adjust every year and equal the total of the 12-month LIBOR index (1.92% as of 13-Dec-20) plus a margin of n/a%.</li>
                        <li>The maximum periodic change in the interest rate is % with a maximum rate increase of % above the initial interest rate.</li>
                        <li>If the interest rate adjusted to the maximum rate (which could not occur until after the fifth year), the maximum monthly payment would be $1,609.94 for $225,000.</li>
                        <li>There is no prepayment penalty.</li>
                        <li>The Annual Percentage Rate (APR) is variable and is subject to increase or decrease, so your payments may increase or decrease each year after the initial period.</li>
                        <li>The chart below shows the interest rate, APR, and the initial monthly payment: </li>
                    </ul>
                    <p>Payment Examples</p>
                    <table className="fullTable">
                        <tbody>
                            <tr className="rateTable">
                                <td>
                                    <b>Loan Amount</b>
                                </td>
                                <td>                                    
                                    <b>Initial Interest Rate (for 60 months)</b>
                                </td>
                                <td className="rateTableAPR">                                    
                                    <b>APR</b>
                                </td>                                  
                                <td>
                                    <b>Initial Monthly Payments (for 60 months)</b>
                                </td>
                            </tr>
                            <tr>
                                <td>$225,000</td>
                                <td>2.13%</td>
                                <td>2.54%</td>
                                <td>$845.78</td>

                            </tr>
                        </tbody>
                    </table>
                    <ul>
                        <li>Disclosure Assumptions</li>
                        <li>Interest rate quoted assumes a FICO score of 720 with a maximum loan-to-value ratio of 80% on a primary residence.</li>
                        <li>The actual interest rate, APR and payment may vary based on the specific terms of the loan selected, verification of information, your credit history, the location and type of property, and other factors as determined by Lenders.</li>
                        <li>The initial interest rate is fixed for 5 years. The disclosed APR is based on 0.00 discount point, 0.00% origination fee and 0.00 additional prepaid finance charges which will be due at closing. First adjusted payment will likely increase to approximately $1,239.35.</li>
                        <li>Interest rate and APR are variable and subject to increase</li>
                        <li>Not available in all states.</li>
                        <li>Rates are subject to change daily without notice.</li>
                        <li>Payment amounts shown do not include taxes, insurance or Private Mortgage Insurance which will impact the monthly payment amount until required LTV is met.</li>

                    </ul>
                    
                </div>
            </Modal>
        </>
    );
}