import React from "react";
import companyLogo from '../assets/images/logo.png';
import AdDisclosurePopup from "./AdDisclosurePopup";

const Header = (props) => {
    return (
        <section id="header">
            <header>
                <div className="container">
                    <div className="row">
                        <a href='/v1' id="mainLogo"><img src={companyLogo}/></a>
                    </div>
                </div>
            </header>            
        </section>

    );
}

export default React.memo(Header);