import React from "react";
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";

export default class Step12 extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {                      
        if (e.currentTarget.value.match(/^[A-Za-z]+$/) || e.currentTarget.value == "") {
            this.props.manualHandleChange(e.currentTarget.dataset.type, e.currentTarget.value)
        }
        
    }

    submitStep = () =>{
        let isError = false;
        if (!this.props.values.firstName.length) {
            this.props.setError('firstName', 'Please fill in your first name.');
            isError = true;
        }        

        if (!this.props.values.lastName.length) {
            this.props.setError('lastName', 'Please fill in your last name.');
            isError = true;
        }

        if (!isError) {
            this.props.next();
        }
    }

    componentDidMount() {
        ReactGA4.event({
            category: 'Step 12, Name',
            action: 'Step 12, Name',
            label: 'Step 12, Name'
        });   
    }

    render () {
        return (
            <div className="step-item col-md-10">
                <div id="section-name" className="section-question section-hide">
                    <div className="row question">
                        <div className="col-md-1"></div>    
                        <div className="col-md-10 text-center">
                            <p>What's your contact information?</p>
                            <small>First, please give us your name.</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            {this.props.isError('firstName') &&
                                <div className="step-errors">
                                    {this.props.values.errorMessage['firstName']}
                                </div>
                            }
                            {this.props.isError('lastName') &&
                                <div className="step-errors">
                                    {this.props.values.errorMessage['lastName']}
                                </div>
                            }
                            
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 input-holder">
                            <input type="text" className={`form-control ${this.props.isError("firstName")? 'invalid': ''}`}
                            data-type="firstName" maxLength="50" placeholder="First Name" autoComplete="off" value={this.props.values.firstName}
                            data-parsley-group="name" data-parsley-required-message="Enter your first name" required 
                            onChange={this.handleChange}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 input-holder">
                            <input type="text" className={`form-control ${this.props.isError("lastName")? 'invalid': ''}`}
                            data-type="lastName" maxLength="50" placeholder="Last Name" autoComplete="off" value={this.props.values.lastName}
                            data-parsley-group="name" data-parsley-required-message="Enter your last name" required 
                            onChange={this.handleChange}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center mt-5 mb-5">
                            <button type="button" id="btnsubmit-name" 
                            className="btn-block btn btn-primary btn-lg btn-text-white btn-350 continueBtn"
                            onClick={this.submitStep}> Continue</button>
                            <button type="button" id="btnsubmit-property-value" className="btn-block btn btn-lg btn-text-white btn-350 backBtn"
                                onClick={this.props.back}> Back </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
