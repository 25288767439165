import React, { useState } from 'react';
import footerLogo from '../assets/images/footer-logo.png';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import OptOut from './OptOut';
import AdDisclosurePopup from './AdDisclosurePopup';
import RefinanceBlock from "../Components/RefinanceBlock";

const Footer = (props) => {
    const currentYear = new Date().getFullYear();
    const [ppIsOpen, setPpIsOpen] = useState(false);
    return (
        <section id="footer">
            <footer className="p-5">
                <div className="container">
                    {(props.step < 2) &&
                    <>
                        <div className="row justify-content-center mt-2">
                            <div className="col-auto">
                                <AdDisclosurePopup/>
                            </div>
                        </div>
                        <RefinanceBlock/>
                        <div className="row mt-2">
                        <div className="col-md-1"></div>
                        <div className="col-md-10 col-sm-12 col-12 text-center  footer-disclaimer">
                            <p>*POTENTIALLY LOWER YOUR OVERALL MONTHLY PAYMENTS - <a href="https://www.fool.com/the-ascent/mortgages/refinance-pay-off-debt/" target="_blank">Motley Fool</a></p>
                            <br/>                            
                            <p>RateDeal.com an RGR Marketing website provides marketing services for various companies involved in mortgage refinance and is not affiliated or endorsed by the VA or any other government agency. The information you provide to us is provided to these companies in real time. If the information you provide to us matches the criteria they are seeking, they will contact you directly. In many cases we may deploy SMS to you to facilitate an inbound call to the service or product provider. We are paid by such providers for each consumer they contact directly and/or provide services or products. You are not charged for our services. We do not guarantee that a provider will accept your request or that their products or services will meet your needs. Their services and products may or may not be the best product or service available on the market. Completing our forms does not obligate you to purchase a service or product nor does it obligate a product or service provider to provide you with any particular service about which you may have inquired. You must be a legal resident of the United States to use this Website and we specifically prohibit access or use by residents of other countries including but not limited to Canadian and European Union Member Citizens. <a href="https://www.nmlsconsumeraccess.org" target="_blank">Nationwide Mortgage Licensing System (NMLS) ID # 2721.</a></p>
                            <ul className="footer-links">
                                <li><a href="http://www.refiexpert.net" target="_blank" id="terms-link">Licenses and Disclosures</a></li>
                            </ul>
                        </div>
                        </div>
                    </>
                    }
                    
                </div>
                <div className='row justify-content-center mt-5 mb-5'>
                    <div className="col-auto">
                        <PrivacyPolicy ppIsOpen={ ppIsOpen } setPpIsOpen={ setPpIsOpen } />
                    </div>
                    <div className="col-auto">
                        <TermsOfUse setPpIsOpen={ setPpIsOpen } />
                    </div>
                    <div className="col-auto">
                        <a className={"pointer-cursor nav-link text-black-50"} target={'_blank'} href="https://privacyportal-eu-cdn.onetrust.com/dsarwebform/942fbf02-bbfe-4a5d-808d-080b06c5651c/3fdf8f1d-7ab3-4a99-bb4e-24965500290d.html">Do Not Sell My Info</a>
                    </div>
                    <div className="col-auto">
                        <OptOut/>
                    </div>
                </div>
                    <div className="row mt-3">
                        <div className="col-md-12 col-sm-12 col-12 text-center">
                            <strong>&copy; {currentYear} RateDeal. All rights reserved.</strong>
                        </div>
                    </div>
            </footer>
        </section>
    );
}

function areEqual(prevProps, nextProps) {
    if (nextProps.step == 2) {
        return false;
    }
    return true;
}

export default Footer;
