import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import parse from "html-react-parser";

export default function PartnerList(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [partners, setPartners] = useState();

    useEffect(() => {
        fetch('https://api.reallygreatrate.com/landingpagecontent/tcpa-partners-list-refinance', {
            method: 'POST',
            body: JSON.stringify({ sitename:"RateCatcher.com" }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
            })
             .then((response) => response.json())
             .then((resp) => {
                setPartners(resp.data.html);     
            })
             .catch((err) => {
                console.log(err.message);
             });
    }, []);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <a className={"pointer-cursor "+(props.noClass?'':'nav-link')} onClick={toggleModal}>partners</a>
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="Partner list"
                className="contentModal"
                overlayClassName="contentModalOverlay"
                ariaHideApp={false}
                partners={partners}
            >
                <div className="contentModalHeader">
                    <div className="row">
                        <div className="col-auto">
                            <h3>MORTGAGE REFINANCE PARTNERS</h3>
                        </div>
                        <div className="col text-right">
                            <button type="button" className="close" onClick={toggleModal} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="contentModalText">
                    {
                         partners && parse(partners)
                    }
                </div>
            </Modal>
        </>
    );
}
