import React from 'react';
import icon1 from '../assets/images/icon-1.png';
import icon2 from '../assets/images/icon-2.png'
import icon3 from '../assets/images/icon-3.png'
import icon4 from '../assets/images/icon-4.png'

const RefinanceBlock = () => {
    return (
        <>  
            <div className="text-center mt-5">
                <h2>Why Refinance Your Mortgage?</h2>
                <p>These are just a few of the benefits of refinancing your mortgage.</p>
            </div>
            <div className="row justify-content-center mt-5 mb-5">
                <div className="col-md col-sm-12 text-center mb-sm-4 blockWithImage">
                    <img src={icon1} className="img-fluid align-self-center" style={{maxWidth:150}}/>
                    <div className="pt-3"><b>Lower your payment</b></div><br/>
                    <div className="mt-2">Today's rates may be much lower than your current rate. That’s why we put together customized quotes for each of our clients.</div>
                </div>
                <div className="col-md col-sm-12 text-center mb-sm-4 blockWithImage">
                    <img src={icon2} className="img-fluid" style={{maxWidth:150}}/>
                    <div className="pt-3"><b>Use your home equity to better manage debt</b></div>
                    <div className="mt-2">Consolidate car payments or credit card debt into your mortgage at lower rates. Let the equity in your home help you to live a stress free life.</div>
                </div>
                <div className="col-md col-sm-12 text-center mb-sm-4 blockWithImage">
                    <img src={icon3} className="img-fluid" style={{maxWidth:150}}/>
                    <div className="pt-3"><b>Pay off your loan faster</b></div><br/>
                    <div className="mt-2">With lower rates you may be able to switch to a 20 or 15 year term without a major payment increase. That means you can pay off your home much faster.</div>
                </div>
                <div className="col-md col-sm-12 text-center blockWithImage">
                    <img src={icon4} className="img-fluid" style={{maxWidth:150}}/>
                    <div className="pt-3"><b>Get a low rate for the life of your loan</b></div>
                    <div className="mt-2">Lock in a 30 year fixed rate all time lows. Rest assured your rate will not increase for the life of your mortgage loan.</div>
                </div>
            </div> 
        </>
    );
}

export default RefinanceBlock;
