import React from "react";
import ReactGA4 from "react-ga4";

export default class Step2 extends React.Component {
    constructor(props) {
        super(props);
        this.handleSelected = this.handleSelected.bind(this);
    }

    handleSelected(e) {
        this.props.manualHandleChange("homeType", e.currentTarget.dataset.val);
        this.props.next();
    }

    componentDidMount() {
        ReactGA4.event({
            category: 'Step 2, Property Type',
            action: 'Step 2, Property Type',
            label: 'Step 2, Property Type'
        });             
        
    }

    render () {
        return (
            <div className="step-item col-md-10">
                <div id="section-property-type" className="section-question section-show">
                    <div className="row question">
                        <div className="col-md-1"></div>    
                        <div className="col-md-10 text-center">
                            <p>What kind of home do you currently own?</p>
                            <small>It can make a difference in the type of loan you can get.</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <div className="buttons property-type">

                                    <div className={`single-family ${this.props.values.homeType === 'Single Family'?'active':''}`} data-val="Single Family" onClick={this.handleSelected}>
                                        <span className="icon"></span>
                                        <span className="text">Single Family</span>
                                    </div>

                                    <div className={`condominium-home ${this.props.values.homeType === 'Condominium'?'active':''}`} data-val="Condominium" onClick={this.handleSelected}>
                                        <span className="icon"></span>
                                        <span className="text">Condominium</span>
                                    </div>

                                    <div className={`town-home ${this.props.values.homeType === 'Town Home'?'active':''}`} data-val="Town Home" onClick={this.handleSelected}>
                                        <span className="icon"></span>
                                        <span className="text">Town Home</span>
                                    </div>

                                    <div className={`mobile-home ${this.props.values.homeType === 'Mobile Home'?'active':''}`} data-val="Mobile Home" onClick={this.handleSelected}>
                                        <span className="icon"></span>
                                        <span className="text">Mobile</span>
                                    </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center mt-5 mb-5">
                            <button type="button" id="btnsubmit-property-value" className="btn-block btn btn-lg btn-text-white btn-350 backBtn"
                                onClick={this.props.back}> Back </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
