import React from "react";
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";

export default class Step10 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dobCorrect: true,
        };
    }

    verfyDate() {
        const year = parseInt(this.props.values.dobYear);
        const month = parseInt(this.props.values.dobMonth);
        const day = parseInt(this.props.values.dobDay);

        let monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

        if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
        monthLength[1] = 29;

        return day > 0 && day <= monthLength[month-1];
    }

    submitStep = () =>{
        let isError = false;

        if (!this.props.values.dobMonth.length) {
            this.props.setError('dobMonth', 'Please select a month.');
            isError = true;
        }
        if (!this.props.values.dobDay.length) {
            this.props.setError('dobDay', 'Please select a day.');
            isError = true;
        }           

        if (!this.props.values.dobYear.length) {
            this.props.setError('dobYear', 'Please select an year.');
            isError = true;
        }

        if(!this.verfyDate()) {
            this.props.setError('dobDay', 'Please select a valid date.');
            isError = true;
        }

        if (!isError) {
            const dob = this.props.values.dobYear + '-' + this.props.values.dobMonth + '-' + this.props.values.dobDay;
            this.props.manualHandleChange('dob', dob);
            this.props.next();
        }
    }

    componentDidMount() {
        ReactGA4.event({
            category: 'Step 10, Date of Birth',
            action: 'Step 10, Date of Birth',
            label: 'Step 10, Date of Birth'
        });

        //Generate a dropdown menu for date
        const date = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];

        const numOfDate = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        
        const dateDropdown = document.getElementById('dob_day');

        for (let i = 0; i < date.length; i++) {
            let dateOption = document.createElement('option');
            dateOption.text = date[i];
            dateOption.value = date[i];
            dateDropdown.add(dateOption);
        }

        // Generate a dropdown menu for month
        let month = {
            'January': '01', 
            'February': '02',
            'March': '03',
            'April': '04',
            'May': '05', 
            'June': '06', 
            'July': '07', 
            'August': '08', 
            'September': '09',
            'October': '10',
            'November': '11', 
            'December': '12'
        };

        const monthDropdown = document.getElementById('dob_month');

        for (let m in month) {
            let monthOption = document.createElement('option');
            monthOption.text = m;
            monthOption.value = month[m];
            monthDropdown.add(monthOption);
        }
        
        const yearDropdown = document.getElementById('dob_year');
        const current = new Date();
        const earliestYear = current.getFullYear()-100;
        let currentYear = current.getFullYear()-18;
        while (currentYear >= earliestYear) {
            let yearOption = document.createElement('option');
            yearOption.text = currentYear;
            yearOption.value = currentYear;
            yearDropdown.add(yearOption);
            currentYear -= 1;
        }
        
        if(this.props.values.dobMonth) {
            this.setState({
                dobMonth: this.props.values.dobMonth
            })
        }
    }

    render () {
        return (
            <div className="step-item col-md-10">
                <div id="section-dob" className="section-question section-hide">
                    <div className="row question">
                        <div className="col-md-1"></div>    
                        <div className="col-md-10 text-center">
                            <p>When were you born?</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            {this.props.isError('dobMonth') &&
                                <div className="step-errors">
                                    {this.props.values.errorMessage['dobMonth']}
                                </div>
                            }
                            {this.props.isError('dobDay') &&
                                <div className="step-errors">
                                    {this.props.values.errorMessage['dobDay']}
                                </div>
                            }
                            {this.props.isError('dobYear') &&
                                <div className="step-errors">
                                    {this.props.values.errorMessage['dobYear']}
                                </div>
                            }
                        </div>
                    </div>
                    <form>
                    <div className="form-group row">
                        <div className="col-md-4"></div>
                        <div className="form-group col-md-4 text-center">
                            <small>Month</small>
                            <select id="dob_month" value={this.props.values.dobMonth} className={`form-control ${!this.props.isError('dobMonth') ? '' : 'invalid'}`} onChange={this.props.handleChange('dobMonth')}>
                                <option value="" disabled>Select Month</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-4"></div>
                        <div className="form-group col-md-4 text-center">
                        <small>Day</small>
                            <select id="dob_day" value={this.props.values.dobDay} className={`form-control ${!this.props.isError('dobDay') ? '' : 'invalid'}`} onChange={this.props.handleChange('dobDay')}>
                            <option value="" disabled>Select Day</option>
                            </select>
                        </div>
                    </div>
                    </form>
                    <div className="form-group row">
                        <div className="col-md-4"></div>
                        <div className="form-group col-md-4 text-center">
                        <small>Year</small>
                            <select id="dob_year" value={this.props.values.dobYear} className={`form-control ${!this.props.isError('dobYear') ? '' : 'invalid'}`} onChange={this.props.handleChange('dobYear')}>
                                <option value="" disabled>Select Year</option>
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center mt-5 mb-5">
                            <button type="button" id="btnsubmit-address" 
                            className="btn-block btn btn-primary btn-lg btn-text-white btn-350 continueBtn" onClick={this.submitStep}> Continue </button>
                            <button type="button" id="btnsubmit-address"  className="btn-block btn btn-lg btn-text-white btn-350 backBtn"
                                onClick={this.props.back}> Back </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
