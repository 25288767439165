import React from "react";
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";
/* global google */

export default class Step11 extends React.Component {
    constructor(props) {
        super(props);
        this.autocompleteInput = React.createRef();
        this.autocomplete = null;        
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);

        this.state = {
            fullAddressCorrect: true
        };
    }

    submitStep = () =>{
        if (this.props.values.zip.length && this.props.values.address.length && this.props.values.city.length && this.props.values.state.length) {
            this.props.next();
        }else {
            this.setState({ fullAddressCorrect: false });
            this.props.setError("address", "Please fill out your street address.");
        }

    }

    handleChange = (e) => {
        this.props.manualHandleChange('address', e.currentTarget.value)
    }
    
    componentDidMount() {
        ReactGA4.event({
            category: 'Step 11, Property Address',
            action: 'Step 11, Property Address',
            label: 'Step 11, Property Address'
        });   
    }

    changeFullAddress = (e) => {
        this.props.manualHandleChange('address',"");
        this.props.manualHandleChange('city',"");
        this.props.manualHandleChange('state',"");
        this.props.manualHandleChange('zip',"");
        this.props.manualHandleChange('fullAddress',e.target.value);
    }

    handlePlaceChanged(){
        const place = this.autocomplete.getPlace();
        this.props.manualHandleChange('fullAddress',place.formatted_address);
        this.setState({ fullAddressCorrect: true });

        for (var i = 0; i < place.address_components.length; i++) {
            let addressType = place.address_components[i].types[0];

            if (addressType === 'street_number') {
                this.props.manualHandleChange('address',place.address_components[i]['short_name']);
            }

            if (addressType === 'route') {
                this.props.manualHandleChange('address',this.props.values.address + " " + place.address_components[i]['short_name']);
            }

            if (addressType === 'locality') {
                this.props.manualHandleChange('city',place.address_components[i]['short_name']);
            }

            if (addressType === 'sublocality_level_1') {
                this.props.manualHandleChange('city',place.address_components[i]['short_name']);
            }

            if (addressType === 'administrative_area_level_1') {
                this.props.manualHandleChange('state',place.address_components[i]['short_name']);
            }

            if (addressType === 'postal_code') {
                this.props.manualHandleChange('zip',place.address_components[i]['short_name']);
            }
        }

    }

    render () {
        return (
            <div className="step-item col-md-10">
                <div id="section-address" className="section-question section-hide">
                    <div className="row question">
                        <div className="col-md-1"></div>    
                        <div className="col-md-10 text-center">
                            <p>What's your street address?</p>
                            <small>For verification only. We do not mail.</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            {this.props.isError('address') &&
                                <div className="step-errors">
                                    {this.props.values.errorMessage['address']}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8 input-holder">
                        {/* <input type="text" value={this.props.values.fullAddress} className={`form-control ${this.state.fullAddressCorrect ? '' : 'invalid'}`} ref={this.autocompleteInput} 
                         id="autocomplete" onChange={this.changeFullAddress} placeholder="Street Address"/> */}
                        <input type="text" className={`form-control ${this.props.isError("address")? 'invalid': ''}`}
                            data-type="address" maxLength="50" placeholder="Street Address" autoComplete="off" value={this.props.values.address}
                            data-parsley-group="address" data-parsley-required-message="Enter your street address" required 
                            onChange={this.handleChange}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <span style={{paddingLeft:'5px'}}>{this.props.values.city}</span>, <span>{this.props.values.state}</span>, <span>{this.props.values.zip}</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center mt-5 mb-5">
                            <button type="button" id="btnsubmit-address" 
                            className="btn-block btn btn-primary btn-lg btn-text-white btn-350 continueBtn" onClick={this.submitStep}> Continue </button>
                            <button type="button" id="btnsubmit-property-value" className="btn-block btn btn-lg btn-text-white btn-350 backBtn"
                                onClick={this.props.back}> Back </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
