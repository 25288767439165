import React from "react";
import InputMask from "react-input-mask";
import ScrollToTop from "../Components/ScrollToTop";
import PartnerList from "../Components/PartnerList";
import TermsOfUse from "../Components/TermsOfUse";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import ReactGA4 from "react-ga4";

export default class Step14 extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = { ppIsOpen: false };
        this.setPpIsOpen = this.setPpIsOpen.bind(this);
    }


    handleChange(e) {              
        this.props.manualHandleChange(e.currentTarget.dataset.type, e.currentTarget.value.replaceAll('+1', ''))
    }

    setPpIsOpen(val) {
        this.setState({ ppIsOpen: val });
    }

    submitStep = () =>{
        let isError = false;
        if (!this.props.values.phone.length) {                
            this.props.setError('phone', 'Please input your phone number.');
            isError = true;
        } else if(this.props.values.phone.length !== 14) {
            this.props.setError('phone', 'Please input your phone number');
            isError = true;
        } else if(!(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(this.props.values.phone))) {
            this.props.setError('phone', 'Please use the correct phone number format');
            isError = true;
        } 
         
        if (this.props.values.phone.length === 14) {
            if (this.props.values.phone[1] === "0" || this.props.values.phone[1] === "1") {
                this.props.setError('phone', 'Please input your phone number with a valid area code');
                isError = true;
            }
        }

        if (!isError) {
            const params = new URLSearchParams(window.location.search);
            params.set('SourceID', this.props.values.publisher_id);
            params.set('ExtClickID', window.leadId);
            params.set('zipcode', this.props.values.zip);
            params.set('State', this.props.values.state);
            params.set('creditscore', this.props.values.creditScore);
            params.set('loanbalance', this.props.values.remainingMortgageBalance);
            params.set('propertyvalue', this.props.values.estimatedHomeValue);
            params.set('PropertyUse', "");
            params.set('MediaChannel', "");
            params.set('EmploymentStatus', "");

            // MAO PII added 

            params.set('FName', encodeURIComponent(this.props.values.firstName));
            params.set('LName', encodeURIComponent(this.props.values.lastName));
            params.set('Email', this.props.values.email);
            params.set('Phone', encodeURIComponent(this.props.values.phone));
            params.set('City', encodeURIComponent(this.props.values.city));
            let maoAddress = encodeURIComponent(this.props.values.address) + "," + encodeURIComponent(" " + this.props.values.city) + "," + encodeURIComponent(" " + this.props.values.state + " " + this.props.values.zip);
            params.set('Address', maoAddress);


            if (this.props.values.cashOut <= 0) {
                params.set('CashOut', 'No');
            }else {
                params.set('CashOut', 'Yes');
            }

            params.set('MilitaryStatus', this.props.values.activeMilitary);            

            if (this.props.values.employmentStatus === 'Employed') {
                params.set('EmploymentStatus', 'Employed');
            }else if (this.props.values.employmentStatus === 'Self-Employed'){
                params.set('EmploymentStatus', 'Self-Employed');
            }else if (this.props.values.employmentStatus === 'Retired'){
                params.set('EmploymentStatus', 'Retired');
            }else if (this.props.values.employmentStatus === 'Other'){
                params.set('EmploymentStatus', 'Not Employed');
            }


            if (this.props.values.homeType === 'Single Family') {
                params.set('PropertyType', 'Single Family');
            }else if (this.props.values.homeType === 'Condominium'){
                params.set('PropertyType', 'Condo/Townhome');
            }else if (this.props.values.homeType === 'Multi-Family Dwelling Unit'){
                params.set('PropertyType', 'Multi-Unit');
            }else if (this.props.values.homeType === 'Mobile Home'){
                params.set('PropertyType', 'Mobile');
            }

            params.toString();            
            window.history.replaceState({}, '', `${window.location.pathname}?${params.toString().replaceAll('+', ' ').replaceAll('%2C', ',')}`);
            this.props.next();
        }

        ScrollToTop();
                
    }

    componentDidMount() {
        ReactGA4.event({
            category: 'Step 14, Phone Number',
            action: 'Step 14, Phone Number',
            label: 'Step 14, Phone Number'
        });       
        
    }

    render () {
        return (
            <div className="step-item col-md-10">
                <div id="section-phone" className="section-question section-hide">
                    <div className="row question">
                        <div className="col-md-1"></div>    
                        <div className="col-md-10 text-center">
                            <p>What number should we call to reach you?</p>
                            <small>We'd like to know your preferred phone number.</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            {this.props.isError('phone') &&
                                <div className="step-errors">
                                    {this.props.values.errorMessage['phone']}
                                </div>
                            }
                            
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 input-holder">
                            {/* <input type="text" className={`form-control ${this.props.isError("phone")? 'invalid': ''}`}
                            data-type="phone" placeholder="(###) ###-####" autocomplete="off" 
                            data-parsley-group="phone" data-parsley-required-message="Enter your phone number" data-parsley-pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$" 
                            data-parsley-pattern-message="Phone number is not valid" data-masked-input="(999) 999-9999" required
                            onChange={this.handleChange}/> */}
                            <InputMask 
                            value={this.props.values.phone}
                            data-type="phone"
                            onChange={this.handleChange}
                            className={`form-control ${this.props.isError("phone")? 'invalid': ''}`}
                            mask="+1\(999) 999-9999"                            
                            placeholder="(###) ###-####"
                            />
                        </div>
                    </div>
                    <label htmlFor="leadid_tcpa_disclosure" >
                        <input type="hidden" id="leadid_tcpa_disclosure" />
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-10 text-center mt-3 mb-2 submit-bottom">
                                <div>By clicking the button below, you acknowledge, consent, and agree to our terms at the bottom of this page.</div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4 text-center mt-2">
                                <button type="button" id="btnsubmit-phone" className="btn-block btn btn-primary btn-lg btn-text-white btn-350 continueBtn"
                                    onClick={this.submitStep}>Submit</button>
                                <button type="button" id="btnsubmit-property-value" className="btn-block btn btn-lg btn-text-white btn-350 backBtn"
                                    onClick={this.props.back}> Back </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-10 text-center mt-3 mb-2 submit-bottom">
                                <div>
                                    By clicking "Submit" you electronically agree to share your information with up to 6 <PartnerList noClass={true}/> (possibly including Rocket
                                    Mortgage) and provide your prior "written" consent for them to contact you
                                    (including through automated means; autodialing, text and pre-recorded
                                    messaging) via telephone, mobile device (including SMS and MMS) and/or
                                    email, even if your telephone number is currently listed on any state,
                                    federal or corporate Do Not Call list and you agree to our <TermsOfUse noClass={true} setPpIsOpen={ this.setPpIsOpen } /> and <PrivacyPolicy noClass={true} ppIsOpen={ this.state.ppIsOpen } setPpIsOpen={ this.setPpIsOpen } />. You agree
                                    that we can share your personal data with third parties, such as our
                                    mortgage partners, service providers and other affiliates, and that we
                                    can use this data for marketing and analytics, and to make your
                                    experience easier. This consent is not a condition of a
                                    purchase.
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        )
    }
}
