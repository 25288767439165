import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import parse from "html-react-parser";

// export default function PrivacyPolicy({ noClass, ppIsOpen, setPpIsOpen}) {
export default function PrivacyPolicy(props) {
    const [content, setContent] = useState(false);
    
    function toggleModal() {
        props.setPpIsOpen(!props.ppIsOpen);
    }

    const customStyles = {
        content: {
          width: '90%',
          height: '70%',
        },
    };
    
    useEffect(() => {
        fetch('https://api.reallygreatrate.com/landingpagecontent/privacy-policy', {
            method: 'POST',
            body: JSON.stringify({ sitename:"RateDeal.com" }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
        })
        .then((response) => response.json())
        .then((resp) => {
            setContent(resp.data.html);
        })
        .catch((err) => {
            console.log(err.message);
        });
    }, []);

    return (
        <>
            <a className={"pointer-cursor "+(props.noClass?'':'nav-link text-black-50')} onClick={toggleModal}>Privacy policy</a>
            <Modal
                isOpen={props.ppIsOpen}
                onRequestClose={toggleModal}
                contentLabel="Privacy policy"
                className="contentModal"
                overlayClassName="contentModalOverlay"
                ariaHideApp={false}
                style={customStyles}
            >
                <div className="contentModalHeader">
                    <div className="row">
                        <div className="col-auto">
                            <h3>PRIVACY POLICY</h3>
                        </div>
                        <div className="col text-right">
                            <button type="button" className="close" onClick={toggleModal} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="contentModalText">
                    {
                        content && parse(content)
                    }  
                </div>
            </Modal>
        </>
    );
}
