import React from "react";
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";
import ReactBootstrapSlider from 'react-bootstrap-slider';
import CurrencyFormat from 'react-currency-format';

export default class Step4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentValue: this.props.values.remainingMortgageBalance,
            homeValue: this.props.values.estimatedHomeValue,
        };
    }

    changeValue = e => {
        this.setState({ currentValue: e.target.value });
        // this.setState({ homeValue: this.props.values.estimatedHomeValue });
        this.props.manualHandleChange('remainingMortgageBalance',e.target.value===60000?59900:e.target.value);
        this.props.manualHandleChange('ltvMax',(this.state.homeValue*0.95 - (e.target.value===60000?59900:e.target.value)));
        this.props.manualHandleChange('cashOut',(this.state.homeValue*0.8 - (e.target.value===60000?59900:e.target.value) - (this.state.homeValue*0.8 - (e.target.value===60000?59900:e.target.value))%5000));


    };


    componentDidMount() {
        ReactGA4.event({
            category: 'Step 4, Mortgage Balance',
            action: 'Step 4, Mortgage Balance',
            label: 'Step 4, Mortgage Balance'
        });       
        
    }

    render () {
        return (
            <div className="step-item col-md-10">
                <div id="section-mortgage-balance" className="section-question section-hide">
                    <div className="row question">
                        <div className="col-md-1"></div>    
                        <div className="col-md-10 text-center">
                            <p>Approximate Mortgage Balance</p>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            {this.props.isError("remainingMortgageBalance") &&
                                <div className="step-errors">
                                    {this.props.values.errorMessage['remainingMortgageBalance']}
                                </div>
                            }
                            
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2"/>
                        <div className="col-md-8 text-center">
                            <div id="home-purchase-price-value" className="price-value">
                            {this.state.currentValue<=60000?'$60,000 or less':''}
                            {this.state.currentValue<2000000 && this.state.currentValue>60000?<span><CurrencyFormat value={this.state.currentValue-14999} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>      :''}
                            {this.state.currentValue > 60000 && this.state.currentValue<2000000?' to ':''}

                            {this.state.currentValue<2000000 && this.state.currentValue>60000?<span><CurrencyFormat value={this.state.currentValue} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>      :''}
                            {this.state.currentValue>=2000000?'Over $2,000,000':''}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2"/>
                        <div className="col-md-8">
                            <div className="property-price-value">
                                <ReactBootstrapSlider
                                    step={15000}
                                    max={2010000}
                                    min={60000}
                                    orientation="horizontal"
                                    className="slider"
                                    tooltip="hide"
                                    value={this.state.currentValue}
                                    change={this.changeValue}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-2"/>
                        <div className="col-md-8">
                            <span className="range-min">$60,000</span><span className="range-max">$2,000,000+</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center mt-5 mb-5">
                        <button type="button" className="btn-block btn  btn-lg btn-text-white btn-350 continueBtn"
                                onClick={this.props.next}> Continue </button>
                        <button type="button" id="btnsubmit-property-value" className="btn-block btn btn-lg btn-text-white btn-350 backBtn"
                                onClick={this.props.back}> Back </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
