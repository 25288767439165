import React from "react";
import ReactGA from 'react-ga';
import ReactGA4 from "react-ga4";

export default class Step13 extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {              
        this.props.manualHandleChange(e.currentTarget.dataset.type, e.currentTarget.value)
    }

    submitStep = () =>{
        let isError = false;
        if (!this.props.values.email.length) {
            this.props.setError('email', "please fill in your email.");
            isError = true;
        } else if (!(/\S+@\S+\.\S+/.test(this.props.values.email))) {
            this.props.setError('email', "please fill in the correct email format.");
            isError = true;
        }

        if (!isError) {
            this.props.next();
        }
    }

    componentDidMount() {
        ReactGA4.event({
            category: 'Step 13, Email',
            action: 'Step 13, Email',
            label: 'Step 13, Email'
        });   
    }

    render () {
        return (
            <div className="step-item col-md-10">
                <div id="section-emailaddress" className="section-question section-hide">
                    <div className="row question">
                        <div className="col-md-1"></div>    
                        <div className="col-md-10 text-center">
                            <p>What's your email address?</p>
                            <small>Let us know how to contact you by email.</small>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            {this.props.isError('email') &&
                                <div className="step-errors">
                                    {this.props.values.errorMessage['email']}
                                </div>
                            }
                            
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 input-holder">
                            <input type="email" className={`form-control ${this.props.isError("email")? 'invalid': ''}`}
                                data-type="email" placeholder="Email Address" maxLength="200" autoComplete="off" value={this.props.values.email}
                                data-parsley-group="email" data-parsley-required-message="Enter your email address" required 
                                onChange={this.handleChange}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center mt-5 mb-5">
                            <button type="button" id="btnsubmit-email" 
                            className="btn-block btn btn-primary btn-lg btn-text-white btn-350 continueBtn"
                            onClick={this.submitStep}> Continue </button>
                            <button type="button" id="btnsubmit-property-value" className="btn-block btn btn-lg btn-text-white btn-350 backBtn"
                                onClick={this.props.back}> Back </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
